<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Company Information
            <v-progress-circular indeterminate color="primary" />
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel
              class="bordered"
              style="background-color: transparent"
            >
              <v-expansion-panel-header>
                <h3>
                  Company Information
                  <router-link
                    style="font-size: 14px"
                    :to="{
                      path: `/dashboard/admin/company/edit/${companyData.id}`,
                    }"
                  >
                    (Edit)
                  </router-link>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-simple-table dense>
                  <thead>
                    <th :width="450"></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Company Name</td>
                      <td>{{ companyData.name }}</td>
                    </tr>
                    <tr>
                      <td>Website</td>
                      <td>{{ companyData.website }}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{{ companyData.phone }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <br />

                <v-simple-table dense>
                  <thead>
                    <th :width="450"></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Lender:</td>
                      <td>{{ companyData.lender | yes_no }}</td>
                    </tr>
                    <tr>
                      <td>Appraiser:</td>
                      <td>{{ companyData.appraiser | yes_no }}</td>
                    </tr>
                    <tr>
                      <td>Lawyer:</td>
                      <td>{{ companyData.lawyer | yes_no }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <br />

                <v-simple-table dense>
                  <thead>
                    <th :width="450"></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Tag</td>
                      <td class="text-capitalize">
                        {{ companyData.lending_profile.tier }}
                      </td>
                    </tr>
                    <tr>
                      <td>Company Active</td>
                      <td>
                        {{ companyData.active | booleanString }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <br />

                <v-simple-table dense>
                  <thead>
                    <th :width="450"></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BC</td>
                      <td>
                        {{ companyData.lending_profile.BC | booleanString }}
                      </td>
                    </tr>
                    <tr>
                      <td>Alberta</td>
                      <td>
                        {{ companyData.lending_profile.AB | booleanString }}
                      </td>
                    </tr>
                    <tr>
                      <td>Ontario</td>
                      <td>
                        {{ companyData.lending_profile.ON | booleanString }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <br />

                <v-simple-table dense>
                  <thead>
                    <th :width="450"></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Lender Stage</td>
                      <td v-if="companyData.kbn_stg !== null">
                        {{ companyData.kbn_stg.stage_name }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mt-10" v-bind="attrs" v-on="on">
                      kyc form<v-icon class="ml-2">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>

                    <v-list-item>
                      <v-list-item-title style="cursor: pointer" @click="create_investor_kyc">
                        Create Manually
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title style="cursor: pointer" @click="send_investor_kyc_link">
                        Send Email
                      </v-list-item-title>
                    </v-list-item>

                  </v-list>
                </v-menu>  
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="12" xl="6">

          <v-expansion-panels :value="0">
            <v-expansion-panel
              class="bordered"
              style="background-color: transparent"
            >
              <v-expansion-panel-header>
                <h3>
                  Form Responses
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                  :headers="form_response_headers"
                  :items="companyData.frm_rsps"
                  :items-per-page="5"
                  @click:row="view_form_response"
                  class="elevation-1"
                >

                <template v-slot:[`item.title`]="{ item }">
                  {{ item.frm !== null ? item.frm.title : '' }} 
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ item.createdAt | dateFormat }} 
                </template>

              </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel
              class="bordered"
              style="background-color: transparent"
            >
              <v-expansion-panel-header>
                <h3>
                  Associated Users
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        path: `/dashboard/lender/edit/${lenderId}`,
                      })
                    "
                  >
                    mdi-account-edit-outline
                  </v-icon>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div v-for="user in companyData.users" :key="user.id">
                  <v-simple-table dense>
                    <thead>
                      <th :width="450"></th>
                      <th></th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>User Name</td>
                        <td>{{ user.first_name }} {{ user.last_name }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{ user.email }}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{{ user.phone }}</td>
                      </tr>
                      <tr>
                        <td>Send Emails on Opportunities</td>
                        <td>{{ user.send_summary_offer_email | yes_no }}</td>
                      </tr>
                      <tr>
                        <td>Blocked Status</td>
                        <td>{{ user.blocked | yes_no }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>

                  <br />
                </div>

                <v-btn
                  @click="$router.push({ path: '/dashboard/admin/user/add' })"
                >
                  Add New User
                </v-btn>

                &nbsp;

                <v-btn
                  @click="
                    $router.push({
                      path: `/dashboard/admin/company/associate_user/`,
                      query: { company_id: `${company_id}` },
                    })
                  "
                >
                  Add Existing User
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';
import filters from '@/plugins/filters';

export default {
  name: 'company_read',
  props: ['company_id'],

  components: {
    // insertNote: () => import("@/components/note/InsertNote.vue"),
    // Note: () => import("@/components/note/Note.vue"),
  },

  data() {
    return {
      panel: 0,
      loadingNotes: false,
      addNoteActive: false,
      componentLoading: true,
      submitError: '',

      formRules: {
        valid: false,
        required: [(value) => !!value || 'This is a required field.'],
      },

      form_response_headers: [
        { text: 'Form #', value: 'id'},
        { text: 'Title', value: 'title'},
        { text: 'Description', value: 'description'},
        { text: 'Status', value: 'status'},
        { text: 'Created', value: 'createdAt'}
      ],

      companyData: {},
      lenderContacts: {},
      lenderAddresses: {},
      lenderNotes: {},
      lenderAccounts: {},
    };
  },

  methods: {
    async refreshNotes() {
      console.log('refresh notes firing read');
      await API()
        .get(`/lenderNotes/${this.lenderId}`)
        .then((res) => {
          this.lenderNotes = res.data;
          this.loadingNotes = false;
          this.closeNote();
        })
        .catch((e) => {
          console.log('the catch block in refreshnotes is firing');
          console.log(e);
        });
    },

    addNote() {
      this.addNoteActive = true;
    },

    closeNote() {
      this.addNoteActive = false;
    },

    create_investor_kyc() {
      this.$router.push({
        path: `/dashboard/admin/form/investor-kyc-2023-v1/read/${this.company_id}/${this.$store.getters['Auth/getAuthUser'].id}`,
      });
    },

    async send_investor_kyc_link() {
      try {
        const res = await API().post(
          'api/form/create_form_access_link',
          {
            company_id: this.company_id,
            form_unique_id: 'investor-kyc-2023-v1',
          }
        );

        if (res.status === 201) {
          console.log(res.data);
        } else {
          console.log('form access link not created.');
        }
      } catch (error) {
        console.log(error);
      }
    },

    view_form_response(value) {
      this.$router.push({
        path: `/dashboard/admin/form_response/${value.frm.unique_id}/read/${value.id}`,
      });
    },
  },

  async mounted() {
    try {
      const companyData = await API().get(
        `/api/internal-admin/company/${this.company_id}`
      );
      console.log(companyData);
      if (companyData.status == 200) {
        this.companyData = companyData.data;
      }

      console.log({ companyData });
    } catch (error) {
      console.error(error);
    }

    this.componentLoading = false;
  },

  filters: {
    ...filters,
  },
};
</script>

<style lane="scss" scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
